.player-wrapper {
    position: relative;
    height: 800px;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 500px;
}
